/* eslint-disable import/no-extraneous-dependencies */
import { Context } from '@nuxt/types'
import { ref } from '@nuxtjs/composition-api'
import {
    getOriginUrl,
    SignUpSteps,
    defineLoginType,
    createSignUp
} from '@/composables/sign-up'
import { Components as Iam } from '@/types/client.iam'
import { unifyPhone } from '@/composables/phone'

export const captchaTokenIam = ref()

const oidcPasswordData = ref()

export interface CustomWindow extends Window { _tmr: any; }

declare let window: CustomWindow

export const useCustomerSignUp = createSignUp(
    'customer',
    ({ context, state }) => {
        const { $apiAuth, $apiIam, $config } = context as Context

        const defineStep = (humanTask?: Iam.Schemas.UserSignupHumanTaskOneOf) => {
            if (humanTask?.approveUserIdentifier) {
                state.step = SignUpSteps.Approve

                return undefined
            }

            if (humanTask?.loginHumanTask) {
                state.step = SignUpSteps.Finish

                return undefined
            }

            if (humanTask?.setPasswordHumanTask) {
                state.step = SignUpSteps.Password

                return undefined
            }

            return SignUpSteps.Registration
        }

        const registrationTracking = (data: Iam.Schemas.UserSignupStep) => {
            // eslint-disable-next-line no-underscore-dangle
            const _tmr = window._tmr || (window._tmr = [])

            if (!_tmr && $config.IS_PROD) return undefined

            _tmr.push({
                id: '3504723',
                type: 'reachGoal',
                goal: 'mt_registration',
                userid: data.userId
            })
        }

        const start = async () => {
            const userIdentifierType = defineLoginType(state.login)
            const value = userIdentifierType === 'PhoneNumber'
                ? unifyPhone(state.login)
                : state.login

            const { data } = await $apiIam.workleUserSignupStartUserSignup(null, {
                transitSource: getOriginUrl(),
                wokleIamUserIdentifier: {
                    userIdentifierType,
                    value
                },
                captchaToken: captchaTokenIam.value
            })

            state.signUpId = String(data.signupId)

            defineStep(data.humanTask)
        }

        const approve = async (code: string) => {
            const { data } = await $apiIam.workleUserSignupApproveUserIdentifier(state.signUpId, {
                code
            })

            defineStep(data.humanTask)
        }

        const setPassword = async (password: string) => {
            const { data } = await $apiIam.workleUserSignupSetUserPassword(
                state.signUpId,
                { password }
            )
            oidcPasswordData.value = data

            defineStep(data.humanTask)
            registrationTracking(data)
        }

        const login = async () => {
            const returnUrl = new URL(window.location.href).searchParams.get('ReturnUrl')

            const { request } = await $apiAuth.accountsLoginPost({
                UserIdentifier: oidcPasswordData.value.humanTask.loginHumanTask.userIdentifier,
                Password: oidcPasswordData.value.humanTask.loginHumanTask.password,
                ReturnUrl: returnUrl
            })

            window.location = request.responseURL
        }

        return {
            type: 'customer',
            state,
            oidcPasswordData,
            login,
            approve,
            start,
            setPassword,
            finish: login
        }
    }
)
